import React, {useEffect, useState} from "react"
import mailIcon from "../../../assets/shareIcon/email.png"
import whatsappIcon from "../../../assets/shareIcon/whatsapp.png"
import {Input, InputBox, notification, Tooltip} from "../../../components/Elements";
import {shareItineraryFxn} from "../../flightSearch/actions";
import {Modal} from "../../../components/Elements";

let initState = {
    name: "",
    mobile: "",
    countryCode: "",
}

const ShareFlightAgentModal = (props) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let {visible, onClose, onSubmit} = props;
    let [state, setState] = useState(initState)
    const events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        },
        onFinalSubmit: () => {
            if (!state.name) {
                return notification.warning({message: "Enter name."})
            }
            if (!state.mobile) {
                return notification.warning({message: "Enter mobile no."})
            }
            onSubmit(state);
        }
    }
    useEffect(() => {
        events._updateState({
            name: user.name,
            mobile: user.mobile,
            countryCode: user.countryCode,
        })
    }, [])


    return (
        <>
            <Modal title={'Share Flight Itinerary'} onClose={onClose} visible={visible} width={'30%'}>
                <InputBox title={'Name'} required={true}>
                    <Input size={'small'} placeholder={'Enter Name'} value={state.name} onChange={(value) => {
                        events._updateState({name: value})
                    }}/>
                </InputBox>
                <InputBox title={'Mobile'} inputClass={'mt-2'} required={true}>
                    <Input size={'small'} placeholder={'Enter Mobile No'} value={state.mobile} onChange={(value) => {
                        events._updateState({mobile: value})
                    }}/>
                </InputBox>
                <div className={'align-center'}>
                    <a className={'btn btn-primary mt-3'} onClick={events.onFinalSubmit}>
                        Share Now
                    </a>
                </div>
            </Modal>
        </>
    )
}

const ShareFlightComponent = (props) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let [state, setState] = useState(initState)
    let {dispatch, data, flightObj} = props;
    let [visibleState, setVisibleState] = useState({
        visible: false,
        type: ""
    });
    const events = {
        showModal: (type) => {
            setVisibleState({
                visible: true,
                type: type
            })
        },
        shareFlight: async (agentData) => {
            let resp = await dispatch(shareItineraryFxn({
                data,
                flightObj,
                agent: {...agentData, countryCode: user.countryCode}
            }));
            events.onClose()
        },
        onClose: () => {
            setVisibleState({
                visible: false,
                type: ""
            })
        }
    }


    return (
        <>
            <div className={'share-icon-box'}>
                <div className={'share-icon'}>
                    <div className={'share-label'}>Share On :</div>
                    <ul>
                        <li>
                            <img src={whatsappIcon} onClick={() => {
                                events.showModal('whatsapp')
                            }}/>
                        </li>
                        {/*    <li>
                        <img src={mailIcon} onClick={()=>{
                                    events.showModal('mail')
                            }}/>

                    </li>*/}
                    </ul>
                </div>
            </div>

            {visibleState.visible ?
                <ShareFlightAgentModal
                    {...visibleState}
                    onClose={events.onClose}
                    onSubmit={events.shareFlight}
                />
                : null}
        </>
    )
}
export default ShareFlightComponent
